.bgPad {
  background-image: url('../../img/app/desk-coffee.jpg');
  background-size: cover;
  background-position: center;
}

.hero {
  background-color: var(--bg-overlay-darker) !important;

  text-align: center;
  padding: 50px 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin: 20px 0;
}

.hero p {
  font-size: 1.2em;
  margin: 20px 0;
}

.cta-button {
  background: #00054b;
  color: white;
  padding: 15px 25px;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  text-transform: uppercase;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  max-width: 300px;
  margin: 20px;
  text-align: center;
}
