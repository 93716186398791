.gorow {
  display: flex !important;
  flex-direction: row !important;
}

.gocol {
  display: flex !important;
  flex-direction: column !important;
}

.divShow {
  display: flex !important;
}

.divHide {
  display: none !important;
}

.showMe {
  display: block !important;
}

.prewrap {
  white-space: pre-wrap !important;
}

.neverwrap {
  white-space: nowrap !important;
}

@media screen and (max-width: 100px) {
  .gocol100 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow100 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W100 {
    width: 100% !important;
  }

  .divShow100 {
    display: flex !important;
  }

  .divHide100 {
    display: none !important;
  }
}

@media screen and (max-width: 150px) {
  .gocol150 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow150 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W150 {
    width: 100% !important;
  }

  .divShow150 {
    display: flex !important;
  }

  .divHide150 {
    display: none !important;
  }
}

@media screen and (max-width: 200px) {
  .gocol200 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow200 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W200 {
    width: 100% !important;
  }

  .divShow200 {
    display: flex !important;
  }

  .divHide200 {
    display: none !important;
  }
}

@media screen and (max-width: 250px) {
  .gocol250 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow250 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W250 {
    width: 100% !important;
  }

  .divShow250 {
    display: flex !important;
  }

  .divHide250 {
    display: none !important;
  }
}

@media screen and (max-width: 300px) {
  .gocol300 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow300 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W300 {
    width: 100% !important;
  }

  .divShow300 {
    display: flex !important;
  }

  .divHide300 {
    display: none !important;
  }
}

@media screen and (max-width: 350px) {
  .gocol350 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow350 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W350 {
    width: 100% !important;
  }

  .divShow350 {
    display: flex !important;
  }

  .divHide350 {
    display: none !important;
  }
}

@media screen and (max-width: 400px) {
  .gocol400 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow400 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W400 {
    width: 100% !important;
  }

  .divShow400 {
    display: flex !important;
  }

  .divHide400 {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .gocol450 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow450 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W450 {
    width: 100% !important;
  }

  .divShow450 {
    display: flex !important;
  }

  .divHide450 {
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .gocol500 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow500 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W500 {
    width: 100% !important;
  }

  .divShow500 {
    display: flex !important;
  }

  .divHide500 {
    display: none !important;
  }
}

@media screen and (max-width: 550px) {
  .gocol550 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow550 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W550 {
    width: 100% !important;
  }

  .divShow550 {
    display: flex !important;
  }

  .divHide550 {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .gocol600 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow600 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W600 {
    width: 100% !important;
  }

  .divShow600 {
    display: flex !important;
  }

  .divHide600 {
    display: none !important;
  }
}

@media screen and (max-width: 650px) {
  .gocol650 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow650 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W650 {
    width: 100% !important;
  }

  .divShow650 {
    display: flex !important;
  }

  .divHide650 {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  .gocol700 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow700 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W700 {
    width: 100% !important;
  }

  .divShow700 {
    display: flex !important;
  }

  .divHide700 {
    display: none !important;
  }
}

@media screen and (max-width: 750px) {
  .gocol750 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow750 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W750 {
    width: 100% !important;
  }

  .divShow750 {
    display: flex !important;
  }

  .divHide750 {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .gocol800 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow800 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W800 {
    width: 100% !important;
  }

  .divShow800 {
    display: flex !important;
  }

  .divHide800 {
    display: none !important;
  }
}

@media screen and (max-width: 850px) {
  .gocol850 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow850 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W850 {
    width: 100% !important;
  }

  .divShow850 {
    display: flex !important;
  }

  .divHide850 {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .gocol900 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow900 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W900 {
    width: 100% !important;
  }

  .divShow900 {
    display: flex !important;
  }

  .divHide900 {
    display: none !important;
  }
}

@media screen and (max-width: 950px) {
  .gocol950 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow950 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W950 {
    width: 100% !important;
  }

  .divShow950 {
    display: flex !important;
  }

  .divHide950 {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .gocol1000 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1000 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1000 {
    width: 100% !important;
  }

  .divShow1000 {
    display: flex !important;
  }

  .divHide1000 {
    display: none !important;
  }
}

@media screen and (max-width: 1050px) {
  .gocol1050 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1050 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1050 {
    width: 100% !important;
  }

  .divShow1050 {
    display: flex !important;
  }

  .divHide1050 {
    display: none !important;
  }
}

@media screen and (max-width: 1100px) {
  .gocol1100 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1100 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1100 {
    width: 100% !important;
  }

  .divShow1100 {
    display: flex !important;
  }

  .divHide1100 {
    display: none !important;
  }
}

@media screen and (max-width: 1150px) {
  .gocol1150 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1150 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1150 {
    width: 100% !important;
  }

  .divShow1150 {
    display: flex !important;
  }

  .divHide1150 {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .gocol1200 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1200 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1200 {
    width: 100% !important;
  }

  .divShow1200 {
    display: flex !important;
  }

  .divHide1200 {
    display: none !important;
  }
}

@media screen and (max-width: 1250px) {
  .gocol1250 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1250 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1250 {
    width: 100% !important;
  }

  .divShow1250 {
    display: flex !important;
  }

  .divHide1250 {
    display: none !important;
  }
}

@media screen and (max-width: 1300px) {
  .gocol1300 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1300 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1300 {
    width: 100% !important;
  }

  .divShow1300 {
    display: flex !important;
  }

  .divHide1300 {
    display: none !important;
  }
}

@media screen and (max-width: 1350px) {
  .gocol1350 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1350 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1350 {
    width: 100% !important;
  }

  .divShow1350 {
    display: flex !important;
  }

  .divHide1350 {
    display: none !important;
  }
}

@media screen and (max-width: 1400px) {
  .gocol1400 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1400 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1400 {
    width: 100% !important;
  }

  .divShow1400 {
    display: flex !important;
  }

  .divHide1400 {
    display: none !important;
  }
}

@media screen and (max-width: 1450px) {
  .gocol1450 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1450 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1450 {
    width: 100% !important;
  }

  .divShow1450 {
    display: flex !important;
  }

  .divHide1450 {
    display: none !important;
  }
}

@media screen and (max-width: 1500px) {
  .gocol1500 {
    display: flex !important;
    flex-direction: column !important;
  }

  .gorow1500 {
    display: flex !important;
    flex-direction: row !important;
  }

  .go100W1500 {
    width: 100% !important;
  }

  .divShow1500 {
    display: flex !important;
  }

  .divHide1500 {
    display: none !important;
  }
}

@media screen and (min-width: 550px) {
  .gorowMin550 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 600px) {
  .divShowMin600 {
    display: flex !important;
  }
}

@media screen and (min-width: 450px) {
  .gorowMin450 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 500px) {
  .gorowMin500 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 550px) {
  .gorowMin550 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 650px) {
  .gorowMin650 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 700px) {
  .gorowMin700 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 750px) {
  .gorowMin750 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 800px) {
  .gorowMin800 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 900px) {
  .gorowMin900 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 990px) {
  .gorowMin990 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 1000px) {
  .gorowMin1000 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 1050px) {
  .gorowMin1050 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 1100px) {
  .gorowMin1100 {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media screen and (min-width: 1200px) {
  .gorowMin1200 {
    display: flex !important;
    flex-direction: row !important;
  }

  .gocolMin1200 {
    display: flex !important;
    flex-direction: column !important;
  }
}