.cGreen > svg {
  fill: var(--c-green);
  height: 20px;
  width: 20px;
}

.cBlue > svg {
  fill: var(--c-cyan);
  height: 20px;
  width: 20px;
}

.cRed > svg {
  fill: var(--c-red);
  height: 20px;
  width: 20px;
}

.cGrey > svg {
  fill: var(--c-gray);
  height: 20px;
  width: 20px;
}

.cRed > svg:hover,
.cGreen > svg:hover,
.cGrey > svg:hover {
  fill: var(--c-cyan);
}

.svgSuccess,
.svgsuccess {
  fill: var(--c-green);
  stroke: var(--c-green);
}

.svgDanger,
.svgdanger {
  fill: var(--c-red);
  stroke: var(--c-red);
}

.svgWarning,
.svgwarning {
  fill: var(--c-yellow);
  stroke: var(--c-yellow);
}

.svgPrimary,
.svgprimary {
  fill: var(--c-cyan);
  stroke: var(--c-cyan);
}

.svgGrey,
.svggrey {
  fill: var(--c-gray);
  stroke: var(--c-gray);
}

.svgStrokeWhite {
  stroke: var(--c-white);
}

.svgStrokeBlack {
  stroke: var(--c-black);
}

.svgStrokeGray100 {
  stroke: var(--c-gray-100);
}

.svgStrokeGray200 {
  stroke: var(--c-gray-200);
}

.svgStrokeGray300 {
  stroke: var(--c-gray-300);
}

.svgStrokeGray400 {
  stroke: var(--c-gray-400);
}

.svgStrokeGray500 {
  stroke: var(--c-gray-500);
}

.svgStrokeGray600 {
  stroke: var(--c-gray-600);
}

.svgStrokeGray700 {
  stroke: var(--c-gray-700);
}

.svgStrokeGray800 {
  stroke: var(--c-gray-800);
}

.svgStrokeGray900 {
  stroke: var(--c-gray-900);
}

.svgStrokeCyan {
  stroke: var(--c-cyan);
}

.svgStrokeGreen {
  stroke: var(--c-green);
}

.svgStrokeRed {
  stroke: var(--c-red);
}

.svgStrokeYellow {
  stroke: var(--c-yellow);
}

.svg05 {
  height: 0.5em;
  width: 0.5em;
}

.svg07 {
  height: 0.7em;
  width: 0.7em;
}

.svg09 {
  height: 0.9em;
  width: 0.9em;
}

.svg12 {
  height: 1.2em;
  width: 1.2em;
}

.svg14 {
  height: 1.4em;
  width: 1.4em;
}

.svg16 {
  height: 1.6em;
  width: 1.6em;
}

.svg18 {
  height: 1.8em;
  width: 1.8em;
}

.svg20 {
  height: 2em;
  width: 2em;
}

.svg22 {
  height: 2.2em;
  width: 2.2em;
}

.svg24 {
  height: 2.4em;
  width: 2.4em;
}

.svg26 {
  height: 2.6em;
  width: 2.6em;
}

.svg28 {
  height: 2.8em;
  width: 2.8em;
}

.svg30 {
  height: 3em;
  width: 3em;
}

.svg32 {
  height: 3.2em;
  width: 3.2em;
}

.svg34 {
  height: 3.4em;
  width: 3.4em;
}

.svg36 {
  height: 3.6em;
  width: 3.6em;
}

.svg38 {
  height: 3.8em;
  width: 3.8em;
}

.svg40 {
  height: 4em;
  width: 4em;
}

.h10 > svg {
  height: 10px;
  width: 10px;
}

.h15 > svg {
  height: 15px;
  width: 15px;
}

.h20 > svg {
  height: 20px;
  width: 20px;
}

.hovSvg > svg {
  width: 15px !important;
  height: 15px !important;
}

.hovSvg > svg:hover {
  color: var(--c-green);
}

.hovSvg {
  transition: all 0.4s;
}

.hovSvg:hover {
  width: 3em !important;
  height: 3em !important;
}

.hovSvgSoft {
  transition: all 0.4s;
}

.hovSvgSoft:hover {
  width: 1.5em !important;
  height: 1.5em !important;
}

.svgHeightUnset {
  height: unset !important;
}

.svgWidthUnset {
  width: unset !important;
}
