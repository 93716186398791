
.navbar-top, .navbar-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "Titillium Web", sans-serif;
  background-color: var(--bg-navbar);
}

.navbar-product {
  background-color: var(--bg-navbar);
  padding: 0.5em 0;
}

.navbar-top a {
  text-decoration: none;
  font-size: 0.8em;
  color: var(--text-navbar);
}

.sp-link {
  color: var(--text-menu);
  margin: 0 5px;
  font-size: 0.9em;
  font-weight:400;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sp-link:hover {
  color: var(--text-contrast-color);
  cursor: pointer;  
}

#dropdown-apps::after, #dropdown-items::after {
  display: none;
}

#dropdown-apps, #dropdown-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}