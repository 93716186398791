.container-sidebar {
  min-width: 25px;
  max-width: 150px;
}

.sideMenuButton {
  background-color: transparent !important;
  border: none;
  width: 100%;
  padding: 10px;
  color: var(--text-contrast-low);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s;
}

.sideMenuButton:hover {
  background-color: var(--bg-contrast-soft) !important;
  color: var(--text-contrast-color);
  font-weight: 600;
  /* padding: 15px 8px; */
}

.sp-label-sidebar {
  font-size: 0.85rem;
  margin-left: 5px;
  min-width: 75px;
  text-align: left;
}
