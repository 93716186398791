.sidebar-juris {
  background-color: unset;
}

.img-juris {
  width: 150px;
}

.form-base {
  max-width: 800px;
}

@media screen and (max-width: 950px) {

  .gocol950 {
    flex-direction: column !important;
  }

  .inputme1 , .inputme2 {
    width: 100% !important;
  }
}
