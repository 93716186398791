

.main-container-sidebar {
  max-width: 230px;
  width: fit-content;
  background-color: var(--w-dark-grey-t);
  padding: 5px;
  margin-right: 5px;
}

.main-container-render {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
}

.button-side {
  color: var(--w-face-color);
  font-family: Mulish,  Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 0.9em !important;
  width: 100%;
  padding: 10px;
  background-color: unset;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.button-side:hover {
  background-color: var(--w-light-blue-t);
  font-weight: 600;
}