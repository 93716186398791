/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.values .box {
  padding: 30px;
  box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
  text-align: center;
  transition: 0.3s;
  height: 100%;
}

.values .box img {
  padding: 30px 50px;
  transition: 0.5s;
  transform: scale(1.1);
}

.values .box h3 {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
}

.values .box:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.values .box:hover img {
  transform: scale(1);
}

.pricing .box {
  padding: 40px 20px;
  background: var(--bg-menu-soft);
  text-align: center;
  box-shadow: 0px 0 30px var(--bg-menu);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.pricing .box:hover {
  transform: scale(1.1);
  box-shadow: 0px 0 30px var(--bg-menu);
}

.pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.pricing .price {
  font-size: 36px;
  color: var(--text-contrast);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .price span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing img {
  padding: 30px 40px;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: var(--text-contrast);
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.pricing ul li {
  padding-bottom: 10px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: #4154f1;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid #4154f1;
}

.pricing .btn-buy:hover {
  background: #4154f1;
  color: #fff;
}

.pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #4154f1;
  color: #fff;
}

.main-home {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-top: 10%;
  margin-bottom: 10%;
}

.logo-main {
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;

}

.img-logo-product {
  width: 100%;
  max-width: 180px;
}

.box, .box-product {
  display: flex;
  width: 100%;
}

.box {
  justify-content: center;
  flex-direction: column;
}

.box-product {
  flex-direction: row;
}

.logo-subscription {
  width: 100%;
  max-width: 220px;
}